
export class RequestResult{
    // This class is used to wrap the result of a fetch request

    constructor(data) {
        this.data = data
    }

    get isSuccessful () {
        return this.data !== null
    }

}

export class BaseDataImport {
    // This class is used to provide a base for the data import process

    constructor() {
        this.isLoading = false
        this.alpine = null
    }

    async request(url, config = null) {
        try {
            if(config === null) {
                config = { method:'GET' }
            }
            this.isLoading = true
            const res = await fetch(`${url}`, config)
            this.isLoading = false
            const data = await res.json()
            return new RequestResult(data)
        }catch (e) {
            console.error(e)
            return new RequestResult(null)
        }
    }
}
