import { WizardBase } from "../wizard-base";

export class ExecuteDataImport extends WizardBase {
    static PREPARING = 'PREPARING'
    static PENDING = 'PENDING'
    static IN_PROCESS = 'IN_PROCESS'
    static COMPLETED = 'COMPLETED'
    static FAILED = 'FAILED'

    STATUS = {
        1: ExecuteDataImport.PREPARING,
        2: ExecuteDataImport.PENDING,
        3: ExecuteDataImport.IN_PROCESS,
        4: ExecuteDataImport.COMPLETED,
        5: ExecuteDataImport.FAILED,
    }

    constructor(manager) {
        super(manager)
        this.currentImportStatus = this.STATUS[1]
        this.percentage = 0
        this.totalImported = 0
        this.totalError = 0
        this.errors = []
    }
    static dataName = 'executeDataImport'

    async preStageAction() {
        this.currentImportStatus = this.STATUS[1]
    }
    getURL(jobId) {
        return `${this._manager.IMPORT_PERCENTAGE_ROUTE}/${jobId}`
    }
    async getUpdate(jobId) {
        const res = await this.request(this.getURL(jobId), {})
        if (res.isSuccessful) {
            this.percentage = res.data.percentage || 0
            this.errors = res.data.errors || []
            this.currentImportStatus = this.STATUS[res.data.status || 1]
            this.totalImported = res.data.total_imported
            this.totalError = res.data.total_errors
            return [4, 5].includes(res.data.status || 1)
        }
    }

    async startPullJobUpdates(jobId) {
        if (jobId) {
            const interval = setInterval(async () => {
                let result = await this.getUpdate(jobId)
                if (result === true) {
                    clearInterval(interval)
                }
            }, 2500)
        }
    }

    async initialFileImport() {
        const postData = new FormData(this._manager.alpine.$refs.form)
        for(const y of this._manager.mapperModel) {
            postData.append('field', y.field.name)
            postData.append('column', y.column)
        }
        const csrfToken = jQuery("[name=csrfmiddlewaretoken]").val()
        const res = await this.request(this._manager.IMPORT_ROUTE, {
            method: 'POST',
            body: postData,
            headers: {
                "X-CSRFToken": csrfToken,
            },
        })
        if (res.isSuccessful) {
            if (res.data.importJobHashId) {
                await this.startPullJobUpdates(res.data.importJobHashId)
            }
            else {
                this.currentImportStatus = this.STATUS[5]
            }
        }
    }

    formatError(error) {
        if (error.mapping) {
            return `${error.message}`
        }
        return gettext('Failed to meet required constraints')
    }

    formatErrorTitle(error) {
        return `=====${gettext('invalid data')}======`.toUpperCase()
    }
}
