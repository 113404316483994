import { WizardBase } from "../wizard-base";

export class ColumnMapping extends WizardBase {
    constructor(manager) {
        super(manager)
        this._mappingCollection = new Map()
        this.mapHistories = []
        this.selectedMapHistory = null
    }

    static dataName = 'columnMapping'

    async init() {
        this.alpine.$watch(
            'step.selectedMapHistory',
            (newValue, oldValue) => this.selectedMapHistoryChanged(newValue, oldValue)
        )
        const res = await this.request(this._manager.MAP_HISTORY_ROUTE)
        if(res.isSuccessful) {
            this.mapHistories = res.data
        }
    }

    validate() {
        return this._manager.mapperModel.filter(x => x.column === null && x.required).length === 0
    }

    resetMapping() {
        this._mappingCollection = new Map()
        this._manager.mapperFileReference.forEach(x => x.selected = false)
        this._manager.mapperModel.forEach(x => x.column = null)
    }

    isSelected (col1, col2) {
        if (col2 === null || col1 === null) {
            return false
        }
        return col1.toString() === col2.toString()
    }

    async preStageAction() {
        this.resetMapping()
    }

    selectedMapperFileReference(e, mapperModel) {
        this.applyMap(e.target.value, mapperModel)
        this.selectedMapHistory = null
    }

    applyMap(column, mapperModel) {
        const mapFileRef = this._manager.getMapperFileReference(column)
        if (this._mappingCollection.has(mapperModel)) {
            const oldValue = this._mappingCollection.get(mapperModel)
            oldValue.selected = false
        }
        if (mapFileRef) {
            mapFileRef.selected = true
            this._mappingCollection.set(mapperModel, mapFileRef)
        }
    }

    getMapHistory(mapId) {
        const mapHistoryFilter = this.mapHistories.filter(x => x.id === mapId)
        return mapHistoryFilter.length === 0 ? null : mapHistoryFilter[0]
    }

    async selectedMapHistoryChanged(newValue, oldValue) {
        if (newValue !== null) {
            this.resetMapping()
            this._manager.isLoading = true
            await this.applyMapConfig(newValue)
            this._manager.isLoading = false
        }
    }

    getMapperModel (fieldName) {
        const mapModelFilter = this._manager.mapperModel.filter(x => x.field.name === fieldName)
        return mapModelFilter.length === 0 ? null : mapModelFilter[0]
    }

    async applyMapConfig(newValue) {
        const mapHistory = this.getMapHistory(newValue)
        if (mapHistory) {
            return new Promise(resolve => {
                mapHistory.config.forEach(map => {
                    const mapperModel = this.getMapperModel(map.field)
                    const column = map.column === 'null'? null: map.column
                    if (mapperModel && column) {
                        mapperModel.column = column
                        this.applyMap(column, mapperModel)
                    }
                })
                resolve()
            })
        }
        return new Promise(resolve => {resolve()})
    }


    formatMapFileRef(mapFileRef) {
        return `${gettext('column')}:${mapFileRef.column + 1} -> ${mapFileRef.samples[0]}`
    }
}
