import {MediaTab} from "~/js/mms-control/base";


export class ChooseFromTab extends MediaTab {
  constructor() {
    super();
    this.existingMedia = []
    this.name = 'chooseFrom'
  }

  async init(alpine_instance) {
    await this.loadMedia()
  }

  itemSelected(event, mediaId) {
    const media = this.getMediaById(mediaId)
    if (media.selected) {
      media.selected = false
      this.removeMedia(mediaId)
    } else {
      media.selected = true
      this.addMedia(media)
    }
  }

  async onLoaded() {
    super.onLoaded();
    await this.loadMedia()
  }

  async loadMedia() {
    const data = await this.request(this.constructMediaURL(MediaTab.MEDIAL_ALL_URL))
    if (data.isSuccessful) {
      this.existingMedia = [...data.data.results]
      this.applySelectedMedia()
    }
  }

  applySelectedMedia() {
    this.existingMedia = this.existingMedia.map(x => {

        if(window.__selected_media_ids && window.__selected_media_ids.includes(x.id)){
          x.selected = true
          return x
        }

        x.selected = false
        return x
      })
  }

  getMediaById(mediaId) {
    const media = this.existingMedia.filter(x => x.id === mediaId)
    if (media.length > 0) {
      return media[0]
    }
  }

  async deleteAction(event, mediaId) {
    event.stopPropagation()
    event.preventDefault()

    const media = this.getMediaById(mediaId)
    if (!media){
      this.alert('Invalid Action')
    }

    const csrfToken = jQuery("[name=csrfmiddlewaretoken]").val()
    const deleteURL = this.constructMediaURL(MediaTab.DELETE_MEDIA_URL).replace(':id', mediaId)
    const res = await this.request(deleteURL, {
      method: 'POST',
      headers: {
        "X-CSRFToken": csrfToken,
      },
    })
    if (res.isSuccessful) {
      await this.loadMedia()
      this.removeMedia(mediaId)
    }
  }

  reset() {
    this.existingMedia = []
  }
}
