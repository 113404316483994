import { WizardBase } from '../wizard-base'

export class SelectFile extends WizardBase {

    constructor(manager) {
        super(manager);
        this.selectedFile = null
    }

    static dataName = 'selectFile'

    async preStageAction() {
        this.selectedFile = null
    }

    async init() {
        await super.init();
        this.alpine.$watch('step.selectedFile', (newValue) => this.onSelectedFileChanged(newValue))
    }

    validate() {
        return this.selectedFile !== null
    }

    async postStageAction() {
        await this._manager.parseFile()
    }

    onSelectedFileChanged(newValue) {
        this._manager.validate()
    }
}
